/* eslint-disable react/no-danger */
import React, { FC, useEffect, createRef } from "react";
import { Layout } from "@components/Layout";
import Pagination from "react-paginate";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Box, Text, Heading, Link, Spinner } from "@primitives";
import {
  FormattedDate as Date,
  FormattedMessage as M,
  useIntl,
} from "gatsby-plugin-intl";
import { useStores } from "@stores";
import { observer } from "mobx-react";
import { until } from "../../styles";
import { TextSection } from "@components/TextSection";
import { PAGE_NAV_ITEMS } from "../../constants/page-nav-items";
import { NaviButtons } from "@components/NaviButtons";
import { getTranslation } from "../../utils/intlUtils";

interface INewsIndexPage {
  data: IMarkdownData;
  file: any;
}
const NewsGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 5rem;
  grid-row-gap: 1rem;
  width: calc(100% - 5rem);
  ${until("md", `grid-template-columns: 100%;`)}
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 1rem);
  ${until("md", `width: 100%;`)}
`;

const NewsBox = styled(Box)`
  padding: 3rem;
`;

const Meta = styled(Text)`
  text-transform: uppercase;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    margin: 0;
    padding: 0;
    li {
      margin: 0 0.5rem;
      &.selected {
        a {
          background: ${(props) => props.theme.colors.mainBlue};
          color: #fff;
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
        }
      }
    }
    a {
      cursor: pointer;
      outline: none;
    }
  }
`;

const SIZE = 10;

const NewsIndexPage: FC<INewsIndexPage> = observer(
  ({
    data: {
      markdownRemark: {
        frontmatter: { blocks: texts },
      },
      file,
    },
  }) => {
    const { locale } = useIntl();
    const {
      newsStore: { getNews, allNews, state },
      agreementStore: { currentEstate },
    }: {
      newsStore: INewsStore;
      agreementStore: IAgreementStore;
    } = useStores();

    const grid = createRef<HTMLDivElement>();

    useEffect(() => {
      getNews(0, SIZE);
    }, [getNews, currentEstate]);

    const onPageChange = async ({ selected }: { selected: number }) => {
      const skip = selected * SIZE;
      const immutable = Object.freeze({ ref: grid.current });
      await getNews(skip, SIZE);
      if (allNews.items.length === 0 && selected !== 0) {
        onPageChange({ selected: selected - 1 });
      } else if (immutable.ref) {
        immutable.ref.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    };

    return (
      <Layout heroImage={file}>
        {state === "Loading" && <Spinner color="mainBlue" />}
        <TextSection
          title={<M id="fields.label.news.title" />}
          text={<M id="fields.label.news.text" />}
          afterTitle={<NaviButtons items={PAGE_NAV_ITEMS.news} />}
        />

        <Container ref={grid}>
          <NewsGrid>
            {!!allNews.items?.length &&
              allNews.items.map((item: INewsItem) => (
                <NewsBox
                  color={item.isExceptionNotice ? "lightOrange" : "lightBlue"}
                  p="3"
                  key={item.id}
                >
                  <StyledLink to={`/news/article/?id=${item.id}`}>
                    <Meta fontWeight="500">
                      <Date value={item.createdAt} />{" "}
                      {item.isExceptionNotice && (
                        <M id="common.exceptionNotice" />
                      )}
                    </Meta>
                    <Heading mt="1" level="3">
                      {getTranslation(locale, item, "title")}
                    </Heading>
                  </StyledLink>
                </NewsBox>
              ))}
          </NewsGrid>
          <PaginationContainer>
            {!!allNews.items?.length && (
              <Pagination
                pageRangeDisplayed={3}
                marginPagesDisplayed={3}
                pageCount={Math.ceil(allNews?.total / SIZE)}
                onPageChange={onPageChange}
                nextLabel={<M id="pages.news.next" />}
                previousLabel={<M id="pages.news.previous" />}
              />
            )}
          </PaginationContainer>
        </Container>
      </Layout>
    );
  }
);

export const pageQuery = graphql`
  query NewsIndexPageQuery {
    markdownRemark(frontmatter: { path: { eq: "/news/" } }) {
      frontmatter {
        title
        path
        blocks {
          text
          id
          title
        }
      }
    }
    file(relativePath: { eq: "my_information_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 936) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default NewsIndexPage;
